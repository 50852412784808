@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'react-toastify/dist/ReactToastify.min.css';

.ant-dropdown-menu {
    border-radius: 0px !important;
    padding: 10px !important;
}
.ant-drawer-body {
    padding: 0px !important;
}
.ant-collapse-header {
    padding: 0px !important;
}
.ant-collapse-expand-icon {
    padding-inline-end: 0px !important;
    margin-inline-start: 0px !important;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: rgb(217, 119, 6) !important;
    font-weight: 500;
}
.ant-tabs-tab:hover {
    color: rgb(217, 119, 6) !important;
}
.ant-tabs-ink-bar {
    height: 5px;
    background: rgb(217, 119, 6) !important;
}

.footer-bg {
    background-image: url("./footer-bg.png");
    background-repeat: no-repeat;
    background-position: center;
}
.ant-spin-dot-item {
    background-color: rgb(217, 119, 6) !important;
}

.ant-pagination-prev:hover,
.ant-pagination-next:hover {
    background-color: rgb(217, 119, 6) !important;
    color: #fff;
    border-color: rgb(217, 119, 6) !important;
}
.ant-pagination .ant-pagination-item-active {
    border-color: rgb(217, 119, 6) !important ;
}
.ant-pagination .ant-pagination-item-active a {
    color: black;
}
.ant-pagination .ant-pagination-item-active a:hover {
    color: black;
}
.ant-select-selector {
    /* border-color: rgb(217, 119, 6) !important ; */
}
.ant-checkbox-checked .ant-checkbox-inner {
    background-color: rgb(217, 119, 6) !important;
    border-color: rgb(217, 119, 6) !important;
}
/* .ant-input-affix-wrapper {
  border-color: rgb(217, 119, 6) !important;
  border-width: 2px !important;
} */

/* Thanh cuộn chính */
::-webkit-scrollbar {
    width: 5px; /* Độ rộng của thanh cuộn */
}

/* Track (phần nền của thanh cuộn) */
::-webkit-scrollbar-track {
    background-color: #f7f2f2;
}

/* Handle (phần di chuyển) */
::-webkit-scrollbar-thumb {
    background-color: #d3d2cf;
}

/* Hover effect cho handle */
::-webkit-scrollbar-thumb:hover {
    background-color: #d3d2cf;
}
